import { createContext, useContext, useMemo, useState } from "react";

type Permission =
  | "freeler:read:all"
  | "freeler:write:all"
  | "company:admin:read:all"
  | "company:admin:write:all"
  | "user:read:all"
  | "user:write:all"
  | "user:admin:read:all"
  | "user:admin:write:all"
  | "event:admin:read:all"
  | "event:read:all"
  | "event:write:all"
  | "event:write:cancel"
  | "config:read:all"
  | "config:write:all"
  | "payment:read:all"
  | "payment:write:all"
  | "remittance:read:all"
  | "remittance:write:all"
  | "billing:read:all"
  | "billing:admin:read:all"
  | "billing:admin:write:all"
  | "overtime:admin:read:all"
  | "overtime:read:all"
  | "overtime:write:all";

type AvailableRoles =
  | "BACKOFFICE"
  | "BACKOFFICE_OPS"
  | "BACKOFFICE_FINANCIAL"
  | "EXTERNAL_BACKOFFICE"
  | "EXTERNAL_BACKOFFICE_OPS";

interface Role {
  name: AvailableRoles;
  permissions: Permission[];
}

const roles: Role[] = [
  {
    name: "BACKOFFICE",
    permissions: [
      "freeler:read:all",
      "freeler:write:all",
      "company:admin:read:all",
      "company:admin:write:all",
      "billing:admin:read:all",
      "billing:admin:write:all",
      "user:admin:read:all",
      "user:admin:write:all",
      "event:admin:read:all",
      "config:read:all",
      "config:write:all",
      "payment:read:all",
      "payment:write:all",
      "remittance:read:all",
      "remittance:write:all",
      "overtime:admin:read:all",
    ],
  },
  {
    name: "BACKOFFICE_OPS",
    permissions: [
      "freeler:read:all",
      "freeler:write:all",
      "user:admin:read:all",
      "user:admin:write:all",
      "company:admin:read:all",
      "company:admin:write:all",
      "event:admin:read:all",
      "config:read:all",
      "config:write:all",
      "overtime:admin:read:all",
    ],
  },
  {
    name: "BACKOFFICE_FINANCIAL",
    permissions: [
      "freeler:read:all",
      "company:admin:read:all",
      "user:admin:read:all",
      "event:admin:read:all",
      "billing:admin:read:all",
      "billing:admin:write:all",
      "payment:read:all",
      "payment:write:all",
      "remittance:read:all",
      "remittance:write:all",
      "overtime:admin:read:all",
    ],
  },
  {
    name: "EXTERNAL_BACKOFFICE",
    permissions: [
      "event:read:all",
      "event:write:all",
      "event:write:cancel",
      "billing:read:all",
      "overtime:read:all",
      "overtime:write:all",
      "user:read:all",
      "user:write:all",
    ],
  },
  {
    name: "EXTERNAL_BACKOFFICE_OPS",
    permissions: ["event:read:all", "event:write:all", "overtime:read:all", "overtime:write:all"],
  },
];

type PermissionsProviderState = {
  permissions: string[];
  userRole: AvailableRoles | null;
  setPermissions: (permissions: string[]) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

const initialState: PermissionsProviderState = {
  permissions: [],
  userRole: null,
  setPermissions: () => null,
  isLoading: true,
  setIsLoading: () => null,
};

const PermissionsProviderContext = createContext<PermissionsProviderState>(initialState);

const PermissionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  function getRoleByPermissions(permissions: Permission[]): AvailableRoles | null {
    for (const role of roles) {
      if (role.permissions.every((permission) => permissions.includes(permission))) {
        return role.name;
      }
    }
    return null;
  }

  const userRole = useMemo(() => {
    const userRole = getRoleByPermissions(permissions as Permission[]);
    return userRole;
  }, [permissions]);

  return (
    <PermissionsProviderContext.Provider
      value={{ permissions, userRole, setPermissions, isLoading, setIsLoading }}
    >
      {children}
    </PermissionsProviderContext.Provider>
  );
};

/**
 * Permissions provider hook.
 * @returns - permissions and setPermissions function.
 */
const usePermissions = () => {
  const context = useContext(PermissionsProviderContext);

  if (context === undefined)
    throw new Error("usePermissions must be used within a PermissionsProvider");

  return context;
};

export { PermissionsProviderContext, PermissionsProvider, usePermissions, type AvailableRoles };
