import { Fragment, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";

import { Menu } from "@ark-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@tremor/react";

import FreelerLogo from "@/assets/images/logo-backoffice.svg";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import useCookie from "@/hooks/useCookies";
import { cn } from "@/lib/utils";
import { usePermissions } from "@/providers/PermissionsProvider";

// eslint-disable-next-line import/no-relative-parent-imports
import * as packageJson from "../../../package.json";

const MainNavbar = () => {
  const location = useLocation();
  const { logout } = useAuth0();
  const [, , deleteCookie] = useCookie("token");
  const { userRole } = usePermissions();

  const isActive = useCallback(
    (path: string) => {
      return path.split("?").at(0) === location.pathname;
    },
    [location.pathname],
  );

  const AVAILABLE_MENU_ITEMS = [
    {
      path: "/operations/dashboard",
      label: "Dashboard",
      roles: ["BACKOFFICE", "BACKOFFICE_OPS", "BACKOFFICE_FINANCIAL", "EXTERNAL_BACKOFFICE", "EXTERNAL_BACKOFFICE_OPS"],
    },
    {
      path: "/events?status=SCHEDULED.COMPLETED.ONGOING",
      label: "Eventos",
      roles: ["BACKOFFICE", "BACKOFFICE_OPS", "BACKOFFICE_FINANCIAL", "EXTERNAL_BACKOFFICE", "EXTERNAL_BACKOFFICE_OPS"],
    },
    {
      path: "/event/overtime-requests?status=PENDING.APPROVED.REJECTED",
      label: "Horas Extras",
      roles: ["BACKOFFICE", "BACKOFFICE_OPS", "BACKOFFICE_FINANCIAL", "EXTERNAL_BACKOFFICE", "EXTERNAL_BACKOFFICE_OPS"],
    },
    {
      path: "/freelancers?status=ACTIVE.AWAITING_APPROVAL",
      label: "Profissionais",
      roles: ["BACKOFFICE", "BACKOFFICE_OPS", "BACKOFFICE_FINANCIAL"],
    },
    {
      path: "/financial/payment-orders?status=ON_TIME.DELAYED",
      label: "Pagamentos",
      roles: ["BACKOFFICE", "BACKOFFICE_FINANCIAL"],
    },
    {
      path: "/billing",
      label: "Faturas",
      roles: ["BACKOFFICE", "BACKOFFICE_FINANCIAL", "EXTERNAL_BACKOFFICE"],
    },
    {
      path: "/users?status=ACTIVE",
      label: "Usuários",
      roles: ["BACKOFFICE", "BACKOFFICE_OPS", "EXTERNAL_BACKOFFICE"],
    },
    {
      path: "/companies?status=ACTIVE",
      label: "Empresas",
      roles: ["BACKOFFICE", "BACKOFFICE_OPS", "BACKOFFICE_FINANCIAL"],
    },
    {
      path: "/settings",
      label: "Configurações",
      roles: ["BACKOFFICE", "BACKOFFICE_OPS"],
    },
  ];

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    deleteCookie();
  };

  return (
    <nav className="fixed top-0 w-full flex items-center justify-between h-16 px-16 bg-white dark:bg-zinc-800 shadow-md z-50">
      <Menu.Root>
        <Menu.ContextTrigger>
          <img src={FreelerLogo} alt="Freeler Logo" />
        </Menu.ContextTrigger>
        <Menu.Positioner>
          <Menu.Content>
            <Menu.Item id="search">Versão {packageJson.version}</Menu.Item>
          </Menu.Content>
        </Menu.Positioner>
      </Menu.Root>
      <div>
        {AVAILABLE_MENU_ITEMS.map((item, index) => {
          return (
            <Fragment key={`${index}-${item.path}`}>
              {item.roles.includes(userRole ?? "") ? (
                <Link to={item.path}>
                  <Button
                    variant="light"
                    className={cn("mr-4", {
                      "text-tremor-brand border-solid border-b-2 border-tremor-brand pb-1":
                        isActive(item.path),
                      "text-gray-500": !isActive(item.path),
                    })}
                  >
                    {item.label}
                  </Button>
                </Link>
              ) : null}
            </Fragment>
          );
        })}
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant="light" className="text-gray-500 hover:text-blue-600">
              Sair
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Deseja realmente sair?</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancelar</AlertDialogCancel>
              <AlertDialogAction variant="primary" color="red" onClick={handleLogout}>
                Sair
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </nav>
  );
};

export { MainNavbar };
